import React, { useRef } from "react"
import { Link, graphql, navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import parse from "html-react-parser"

import "../styles/@wordpress/block-library/build-style/style.css"
import "../styles/@wordpress/block-library/build-style/theme.css"
import "./style.blog.scss"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ContactModal from "../components/contactModal"

const BlogPostTemplate = ({ data: { previous, next, post } }) => {
  const modalRef = useRef()
  const description = post.categories?.nodes[0].description || ""
  const keywords = post.categories?.nodes[0].slug || ""

  return (
    <Layout>
      <ContactModal ref={modalRef} />
      <Seo
        title={post.title}
        keywords={decodeURIComponent(keywords)}
        description={description}
      />
      <div className="relative">
        <StaticImage
          src="./banner.png"
          alt="行业洞察_装箱装柜软件_外贸装柜软件-蓝胖智汇"
          className="w-full"
        />
        <div className="absolute top-28 left-24 text-white text-3xl">
          行业洞察
        </div>
      </div>
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <div className="breadcrumb">
            <Link to="/news" className="level1">
              行业洞察
            </Link>
            {" / "}
            <span className="level2">详情</span>
          </div>
          <h1 itemProp="headline">{parse(post.title)}</h1>

          <p className="publish-date">发布日期：{post.date}</p>
        </header>
        <div className="blog-body">
          {!!post.content && (
            <section itemProp="articleBody" className="blog-content">
              {parse(post.content)}
            </section>
          )}
        </div>
        <hr />

        <footer></footer>
      </article>

      <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={`/news/${previous.id}`} rel="prev">
                ← {parse(previous.title)}
              </Link>
            )}
          </li>

          <li>
            {next && (
              <Link to={`/news/${next.id}`} rel="next">
                {parse(next.title)} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
      <div id="misc">
        <div className="content">
          <div className="qa">
            <div className="title">其他问题</div>
            <div className="detail">
              告诉我们您需要解决什么问题，专家会帮助您找到最适合您的解决方案
            </div>
            <button
              onClick={() => modalRef.current.openModal()}
              className="contact-us-now"
              gtm-id="cases-contact-us"
            >
              联系我们
            </button>
          </div>
          <div className="try">
            <div className="product">
              <div className="about">更多产品</div>
              <div
                className="go check-products"
                onClick={() => navigate("/products")}
              >
                查看更多产品
              </div>
            </div>
            <div className="product">
              <div className="about">继续浏览</div>
              <div
                className="go check-cases"
                onClick={() => navigate("/cases")}
              >
                查看更多成功案例
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      date(formatString: "YYYY-MM-DD")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: TRACED_SVG
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
      categories {
        nodes {
          description
          slug
        }
      }
    }
    previous: wpPost(id: { eq: $previousPostId }) {
      id
      uri
      title
    }
    next: wpPost(id: { eq: $nextPostId }) {
      id
      uri
      title
    }
  }
`
